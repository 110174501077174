import React from 'react'
import JsonTable from './json-table';

const PosBalances = ({ balances }) => {
    let jsonTable;

    if (balances[0]) {
        jsonTable = <JsonTable data={balances}/>
    }

    return (
        <div>
            <h2>Position Account Balances</h2>
            {jsonTable}
        </div>
    )
};

export default PosBalances