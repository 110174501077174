import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import App from './app.js'
import Route2 from './route2.js'

const routing = (
    <Router>
        <div>
            {/*
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/route2">Route 2</Link>
                </li>
            </ul>
            */}
            <Route exact path="/" component={App} />
            <Route path="/route2" component={Route2} />
        </div>
    </Router>
)

ReactDOM.render(routing, document.getElementById('root'))