import React from 'react'

class FxTransferForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customer_id: "Pavel",
            currency: "CZK",
            currency2: "EUR",
            amount: 0,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);

        fetch(process.env.REACT_APP_API_URL + '/business_transaction', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                type: 'CUSTOMER_FX',
                amount: formData.get('amount'), currency: formData.get('currency'), currency2: formData.get('currency2'), customer_id: formData.get('customer_id') }),
        }).then(
            (result) => {
                if (result.status !== 201) {
                    alert('There has been a problem with your fetch operation: ' + result.statusText)
                } else {
                    this.props.fxTransferSent()
                }
            },
            (error) => {
                alert('Error')
            }
        )

        this.setState({amount: 0})
    }

    render() {
        let customersList = this.props.customers.length > 0
            && this.props.customers.map((item, i) => {
                return (
                    <option key={i} value={item.customer_id}>{item.customer_id}</option>
                )
            }, this);
        let currenciesList = this.props.currencies.length > 0
            && this.props.currencies.map((item, i) => {
                return (
                    <option key={i} value={item.currency}>{item.currency}</option>
                )
            }, this);

        return (
            <form onSubmit={this.handleSubmit}>
                <h3>Customer F/X conversion</h3>
                <label>
                    Customer:
                    <select name="customer_id" value={this.state.customer_id} onChange={this.handleChange}>
                        {customersList}
                    </select>
                </label>
                <label>
                    Currency FROM:
                    <select name="currency" value={this.state.currency} onChange={this.handleChange}>
                        {currenciesList}
                    </select>
                </label>
                <label>
                    Currency TO:
                    <select name="currency2" value={this.state.currency2} onChange={this.handleChange}>
                        <option value="EUR">EUR</option>
                        <option value="CZK">CZK</option>
                        <option value="PLN">PLN</option>
                    </select>
                </label>
                <label>
                    Amount:
                    <input name="amount" type="number" value={this.state.amount} onChange={this.handleChange} />
                </label>
                <input type="submit" value="Submit" />
            </form>
        );
    }
}

export default FxTransferForm