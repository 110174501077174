import React from 'react'
import JsonTable from './json-table';

const Transactions = ({ transactions, title }) => {
    let jsonTable;

    if (transactions[0]) {
        jsonTable = <JsonTable data={transactions}/>
    }

    return (
        <div>
            <h2>{title} Transactions</h2>
            {jsonTable}
        </div>
    )
};

export default Transactions