import React from 'react'
import CustomerBalances from './components/customer-balances';
import PosBalances from './components/pos-balances';
import Transactions from './components/transactions';
import BankTransferForm from "./components/bank-transfer-form";
import FxTransferForm from "./components/fx-transfer-form";
import HedgingForm from "./components/hedging-form";
import AddCustomerAndCurrencyForm from "./components/add-customer-and-currency-form";

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            customerBalances: [],
            posBalances: [],
            revenueTransactions: [],
            customers: [],
            currencies: [],
        }
        this.handleBankTransferSent = this.handleBankTransferSent.bind(this)
        this.handleFxTransferSent = this.handleFxTransferSent.bind(this)
        this.handleHedgingSent = this.handleHedgingSent.bind(this)
        this.handleAddCustomerOrCurrency = this.handleAddCustomerOrCurrency.bind(this);
    }

    loadCustomerBalances() {
        fetch(process.env.REACT_APP_API_URL + '/customer_balances')
            .then(res => res.json())
            .then((data) => {
                this.setState({ customerBalances: data })
            })
            .catch(console.log)
    }

    loadPosBalances() {
        fetch(process.env.REACT_APP_API_URL + '/pos_balances')
            .then(res => res.json())
            .then((data) => {
                this.setState({ posBalances: data })
            })
            .catch(console.log)
    }

    loadRevenueTransactions() {
        fetch(process.env.REACT_APP_API_URL + '/transaction?or=(type.eq.REVENUE_SPREAD,type.eq.REVENUE_NETTING)&select=amount, currency, type')
            .then(res => res.json())
            .then((data) => {
                this.setState({ revenueTransactions: data })
            })
            .catch(console.log)
    }

    loadCustomersAndCurrencies() {
        fetch(process.env.REACT_APP_API_URL + '/multicurrency_account?type=eq.customer&select=customer_id')
            .then(res => res.json())
            .then((data) => {
                this.setState({ customers: data })
            })
            .catch(console.log)

        fetch(process.env.REACT_APP_API_URL + '/currency_priority?select=currency&order=priority.asc')
            .then(res => res.json())
            .then((data) => {
                this.setState({ currencies: data })
            })
            .catch(console.log)
    }

    handleBankTransferSent() {
        this.loadCustomerBalances()
    }

    handleFxTransferSent() {
        this.loadCustomerBalances()
        this.loadPosBalances()
    }

    handleHedgingSent() {
        this.loadPosBalances()
        this.loadRevenueTransactions()
    }

    handleAddCustomerOrCurrency() {
        this.loadCustomerBalances()
        this.loadCustomersAndCurrencies()
    }

    componentDidMount() {
        this.loadCustomerBalances()
        this.loadPosBalances()
        this.loadRevenueTransactions()
        this.loadCustomersAndCurrencies()
    }

    render() {
        return (
            <div>
                <BankTransferForm bankTransferSent={this.handleBankTransferSent} customers={this.state.customers} currencies={this.state.currencies} />
                <FxTransferForm fxTransferSent={this.handleFxTransferSent} customers={this.state.customers} currencies={this.state.currencies} />
                <AddCustomerAndCurrencyForm addFormSent={this.handleAddCustomerOrCurrency} />
                <HedgingForm hedgingSent={this.handleHedgingSent} />
                <CustomerBalances balances={this.state.customerBalances} />
                <PosBalances balances={this.state.posBalances} />
                <Transactions transactions={this.state.revenueTransactions} title="Revenue" />
            </div>
        )
    }
}
export default App