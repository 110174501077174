import React from 'react'

export default class HedgingForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        fetch(process.env.REACT_APP_API_URL + '/rpc/hedge', {
            method: 'POST',
        }).then(
            (result) => {
                if (result.status !== 200) {
                    alert('There has been a problem with your fetch operation: ' + result.statusText)
                } else {
                    this.props.hedgingSent()
                }
            },
            (error) => {
                alert('Error')
            }
        )
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <input type="submit" value="Hedge!" />
            </form>
        );
    }
}
