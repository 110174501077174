import React from 'react'

export default class AddCustomerAndCurrencyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customer_id: "",
            currency: "",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitCustomer = this.handleSubmitCustomer.bind(this);
        this.handleSubmitCurrency = this.handleSubmitCurrency.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmitCustomer(event) {
        event.preventDefault();
        const formData = new FormData(event.target);

        fetch(process.env.REACT_APP_API_URL + '/rpc/new_customer', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                _customer_id: formData.get('customer_id')
            })
        }).then(
            (result) => {
                if (result.status !== 200) {
                    alert('There has been a problem with your fetch operation: ' + result.statusText)
                } else {
                    this.props.addFormSent()
                }
            },
            (error) => {
                alert('Error')
            }
        )

        this.setState({customer_id: ""})
    }

    handleSubmitCurrency(event) {
        event.preventDefault();
        const formData = new FormData(event.target);

        fetch(process.env.REACT_APP_API_URL + '/rpc/new_currency', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                _currency: formData.get('currency')
            })
        }).then(
            (result) => {
                if (result.status !== 200) {
                    alert('There has been a problem with your fetch operation: ' + result.statusText)
                } else {
                    this.props.addFormSent()
                }
            },
            (error) => {
                alert('Error')
            }
        )

        this.setState({currency: ""})
    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmitCustomer}>
                    <h4>Add Customer</h4>
                    <label>
                        Customer name:
                        <input name="customer_id" type="text" value={this.state.customer_id} onChange={this.handleChange} />
                    </label>
                    <input type="submit" value="Submit" />
                </form>
                {/* Nejprve je potreba doplnit nastaveni kurzu
                <form onSubmit={this.handleSubmitCurrency}>
                    <h4>Add Currency</h4>
                    <label>
                        Currency ISO code:
                        <input name="currency" type="text" value={this.state.currency} onChange={this.handleChange} />
                    </label>
                    <input type="submit" value="Submit" />
                </form>
                    */}
            </div>
        );
    }
}